import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';

class LinkedInSingle extends React.Component {
    static propTypes = {
        text: PropTypes.string,
        headline: PropTypes.string,
        linkDescription: PropTypes.string,
        displayUrl: PropTypes.string,
        image: PropTypes.string,
        cta: PropTypes.string,
        asset: PropTypes.object
    };
    static defaultProps = {};

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 620}
                    width={320}
                />
            );
        }
    };

    render() {
        const { headline, cta, linkedin_cta, displayUrl, assetComponents, adType } = this.props;
        let fileType = 'image';

        // Check if the asset type is an image or a video. If it is a video, don't show the displayURL and show a 'Learn more' button
        if (assetComponents && assetComponents[0] && assetComponents[0].props && assetComponents[0].props.image !== undefined) {
            fileType = 'image';
        } else if (assetComponents && assetComponents[0] && assetComponents[0].props && assetComponents[0].props.video !== undefined) {
            fileType = 'video';
        }

        return (
            <div className="single">
                <div className="single__asset-wrapper">{this.getAsset()}</div>
                {fileType === 'image' && adType !== 'post' && (
                    <div className="single__info">
                        <div className="single__text-wrapper">
                            <div className="single__headline">{headline}</div>
                            <div className="single__displayUrl">{displayUrl}</div>
                        </div>
                        {cta && cta !== '' && cta !== 'NO_BUTTON' && linkedin_cta[cta] && (
                            <div className="single__info__cta-wrapper">
                                <a href={displayUrl} target="_blank" className="single__info__cta">
                                    {linkedin_cta[cta]}
                                </a>
                            </div>
                        )}
                    </div>
                )}

                {fileType === 'video' && adType !== 'post' && (
                    <div className="single__video">
                        <div className="single__video__info">
                            <div className="single__video__text-wrapper">
                                <div className="single__video__headline">
                                    <span>{headline}</span>
                                </div>
                            </div>

                            <div className="single__video__cta-wrapper">
                                <a href={displayUrl} target="_blank" className="single__video__cta">
                                    LEARN MORE
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default LinkedInSingle;
