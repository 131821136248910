import './../styles/main.scss';
import PropTypes from 'prop-types';
import React from 'react';
import { withResources } from 'components/data/Resources';
import FacebookSingle from './facebook-single';
import FacebookMultiple from './facebook-multiple';
import FacebookCarouselAd from './facebook-carousel-ad';
import FacebookStoriesAd from './facebook-stories-ad';
import iconMore from '../images/icon-more.svg';
import iconSponsored from '../images/icon-sponsored.svg';
import iconLike from '../images/icon_like.png';
import iconComment from '../images/icon_comment.png';
import iconShare from '../images/icon_share.png';

class PreviewFacebook extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        text: PropTypes.string,
        headline: PropTypes.string,
        linkDescription: PropTypes.string,
        displayUrl: PropTypes.string,
        image: PropTypes.string,
        cta: PropTypes.string,
        frameCount: PropTypes.number,
        items: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                    headline: PropTypes.string,
                    linkDescription: PropTypes.string,
                    displayUrl: PropTypes.string,
                    image: PropTypes.string,
                    cta: PropTypes.string
                })
            )
        ])
    };

    static defaultProps = {
        adType: 'linkAd',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            showFullText: false
        };
    }

    showFullText = () => {
        this.setState({ showFullText: true });
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'linkAd':
                component = <FacebookSingle {...props} />;
                break;

            case 'post':
                component = <FacebookSingle {...props} />;
                break;

            case 'dynamic':
                component = <FacebookSingle {...props} />;
                break;
            case 'dynamicCarousel':
                component = <FacebookCarouselAd {...props} />;
                break;

            case 'multipleAssetsPost':
                component = <FacebookMultiple {...props} />;
                break;

            case 'videoAd':
                component = <FacebookSingle {...props} />;
                break;

            case 'carouselAd':
                component = <FacebookCarouselAd {...props} />;
                break;

            case 'carouselPost':
                component = <FacebookCarouselAd {...props} />;
                break;

            case 'productCarousel':
                component = <FacebookCarouselAd {...props} />;
                break;

            case 'storiesAd':
                component = <FacebookStoriesAd {...props} />;
                break;

            case 'storiesPost':
                component = <FacebookStoriesAd {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    transformText = (text) => {
        const { showFullText } = this.state;

        if (text) {
            const trimmedArray = text.trim ? text.trim().split(' ') : [];
            let textArray = [];

            // Check for enters in every word. If there are any, push them to the textArray as an '\n'
            trimmedArray.forEach((word) => {
                if (word.includes('\n')) {
                    const splitWords = word.split(/(\n)/g);
                    splitWords.forEach((word) => {
                        if (word !== '') {
                            textArray.push(word);
                        }
                    });
                } else {
                    textArray.push(word);
                }
            });

            // Map the textArray and check for hashtags
            textArray = textArray.map((word) => {
                if (word[0] === '#') {
                    return { word, hashtag: true };
                } else {
                    return { word };
                }
            });

            let characterCount = 0;
            let filteredWordsArray = [];
            const maxLength = 208;

            // Check if the text should be truncated. If so, end with '...more'
            if (showFullText) {
                filteredWordsArray = textArray;
            } else {
                textArray.every((word) => {
                    const length = word.word.length;
                    if (characterCount + length <= maxLength) {
                        characterCount += length;
                        filteredWordsArray.push(word);
                        return true;
                    } else {
                        filteredWordsArray.push({
                            word: '...more',
                            showMore: true
                        });
                        return false;
                    }
                });
            }

            return (
                <div>
                    {filteredWordsArray.map((word, index) => {
                        if (word.hashtag) {
                            return (
                                <span key={word.word + index} className="hashtag">
                                    {word.word}{' '}
                                </span>
                            );
                        } else if (word.showMore) {
                            return (
                                <span key={word.word + index} className="showMore" onClick={() => this.showFullText()}>
                                    {word.word}{' '}
                                </span>
                            );
                        } else if (word.word === '\n') {
                            return <br key={index} />;
                        } else {
                            return <span key={word.word + index}>{word.word} </span>;
                        }
                    })}
                </div>
            );
        }
    };

    returnFacebookFrame = (postComponent) => {
        const { adType, advertiser } = this.props;
        const message = this.props.message ? this.props.message : this.props.text;

        return (
            <div className={'social-preview__facebook__wrapper'}>
                <div className="social-preview__facebook__wrapper__advertiser">
                    <div className="social-preview__facebook__wrapper__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />

                    <div className="social-preview__facebook__wrapper__advertiser__text">
                        <div className="social-preview__facebook__wrapper__advertiser__name">{advertiser.name}</div>
                        {adType !== 'post' && adType !== 'carouselPost' && adType !== 'multipleAssetsPost' && (
                            <div className="social-preview__facebook__wrapper__advertiser__sponsored">
                                Sponsored ·
                                <img className="social-preview__facebook__wrapper__advertiser__globe" src={iconSponsored} alt={'icon-sponsored'} />
                            </div>
                        )}
                        {(adType === 'post' || adType === 'carouselPost' || adType === 'multipleAssetsPost') && (
                            <div className="social-preview__facebook__wrapper__advertiser__sponsored">
                                Yesterday at 18:30 ·
                                <img className="social-preview__facebook__wrapper__advertiser__globe" src={iconSponsored} alt={'icon-sponsored'} />
                            </div>
                        )}
                    </div>

                    <div className="social-preview__facebook__wrapper__advertiser__more">
                        <img className="social-preview__facebook__wrapper__advertiser__more_icon" src={iconMore} alt={'icon-more'} />
                    </div>
                </div>

                {message && (
                    <div className="social-preview__facebook__wrapper__text" id="text">
                        {this.transformText(message)}
                    </div>
                )}

                {postComponent}

                <div className="social-preview__facebook__wrapper__actions">
                    <div className="social-preview__facebook__wrapper__actions__action">
                        <img className="social-preview__facebook__wrapper__actions__action__icon" src={iconLike} />
                        Like
                    </div>
                    <div className="social-preview__facebook__wrapper__actions__action">
                        <img className="social-preview__facebook__wrapper__actions__action__icon" src={iconComment} />
                        Comment
                    </div>
                    <div className="social-preview__facebook__wrapper__actions__action">
                        <img className="social-preview__facebook__wrapper__actions__action__icon" src={iconShare} />
                        Share
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { adType, isEditor } = this.props;

        // Show as dynamic
        if (adType === 'dynamic' && isEditor) {
            return (
                <div className="social-preview__facebook social-preview__facebook--dynamic">
                    <div className="social-preview__facebook__story">
                        <FacebookStoriesAd {...this.props} assetSize="story" />
                    </div>
                    <div className="social-preview__facebook__post">{this.returnFacebookFrame(<FacebookSingle {...this.props} assetSize="square" />)}</div>
                </div>
            );
        } else if (adType === 'dynamicCarousel' && isEditor) {
            return (
                <div className="social-preview__facebook social-preview__facebook--dynamic">
                    <div className="social-preview__facebook__story">
                        <FacebookStoriesAd {...this.props} assetSize="story" />
                    </div>
                    <div className="social-preview__facebook__post">{this.returnFacebookFrame(<FacebookCarouselAd {...this.props} assetSize="square" />)}</div>
                </div>
            );
        }
        // Stories, just show the story
        else if (adType === 'storiesAd' || adType === 'storiesPost') {
            return (
                <div className="social-preview__facebook">
                    <div>{PreviewFacebook.getChild(adType, this.props)}</div>
                </div>
            );
        }
        // Show in a frame of Facebook
        else {
            return (
                <div className="social-preview__facebook">
                    <div>{this.returnFacebookFrame(PreviewFacebook.getChild(adType, this.props))}</div>
                </div>
            );
        }
    }
}

export default withResources(PreviewFacebook, ['facebook_cta']);
