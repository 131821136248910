import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import iconMore from '../images/icon_more.svg';
import iconArrowRight from '../images/icon_arrow_right.svg';

class LinkedInTextAd extends React.Component {
    static propTypes = {
        frames: PropTypes.array
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            showArrow: false
        };
    }

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1200}
                    width={56}
                    height={56}
                />
            );
        }
    };

    handleHover = (move) => {
        if (move === 'enter') {
            this.setState({ showArrow: true });
        } else {
            this.setState({ showArrow: false });
        }
    };

    render() {
        const { headline, displayUrl, description } = this.props;
        const { showArrow } = this.state;

        return (
            <div className="text-ad">
                <div className="text-ad__title-bar">
                    <div className="text-ad__title-bar__text">Promoted</div>
                    <div className="text-ad__title-bar__icon">
                        <img className="text-ad__title-bar__more-icon" src={iconMore} alt={'icon-more'} />
                    </div>
                </div>

                <div className="text-ad__items-list">
                    <div className="text-ad__items-list__item" onMouseEnter={() => this.handleHover('enter')} onMouseLeave={() => this.handleHover('leave')}>
                        <div className="text-ad__items-list__item__image-wrapper">{this.getAsset()}</div>
                        <div className="text-ad__items-list__item__text-wrapper">
                            <div className="text-ad__items-list__item__headline">{headline}</div>
                            <div className="text-ad__items-list__item__description">{description}</div>
                        </div>

                        {showArrow && (
                            <a href={displayUrl} target="_blank" className="a">
                                <div className="text-ad__items-list__item__arrow-right">
                                    <img className="text-ad__items-list__item__icon-arrow-right" src={iconArrowRight} alt={'icon-arrow-right'} />
                                </div>
                            </a>
                        )}
                    </div>

                    <div className="text-ad__items-list__item">
                        <div className="text-ad__items-list__item__image-placeholder"></div>
                    </div>
                    <div className="text-ad__items-list__item">
                        <div className="text-ad__items-list__item__image-placeholder"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinkedInTextAd;
