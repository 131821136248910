import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import DynamicAsset from 'components/assets/DynamicAsset';

class LinkedInCarouselAd extends React.Component {
    static propTypes = {
        frames: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                    headline: PropTypes.string,
                    linkDescription: PropTypes.string,
                    displayUrl: PropTypes.string,
                    image: PropTypes.string,
                    cta: PropTypes.string
                })
            )
        ])
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            scrollLeft: 0
        };
        this.linkedinCarouselRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.linkedinCarouselRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    swipe = (direction) => {
        const element = this.linkedinCarouselRef.current;
        const distance = 270;

        if (direction === 'right') {
            element.scrollLeft += distance;
        } else {
            if (element.scrollLeft % distance === 0) {
                element.scrollLeft += -distance;
            } else {
                element.scrollLeft -= element.scrollLeft % distance;
            }
        }

        // setTimeout because of the animation time (smooth scroll)
        setTimeout(() => {
            this.setState({ scrollLeft: element.scrollLeft });
        }, 340);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.linkedinCarouselRef.current;
        const distance = 270;

        this.setState({ scrollLeft: (frameNr - 1) * distance }, () => (element.scrollLeft = (frameNr - 1) * distance));
    };

    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // existing prop
        if (assetComponents) {
            return assetComponents[index] && assetComponents[index][0] ? assetComponents[index][0] : <React.Fragment />;
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 600}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 600}
                    width={260}
                />
            );
        }
    };

    render() {
        const { frames = [], items } = this.props;
        let { frameCount = 0 } = this.props;
        const { scrollLeft } = this.state;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        if (frameCount === 0) {
            frameCount = framesRestructured.length;
        }

        return (
            <div className="carousel-ad">
                <div className="carousel-ad__carousel" ref={this.linkedinCarouselRef}>
                    {framesRestructured.map(({ headline, asset, image, video }, index) => (
                        <div className="carousel-ad__carousel__item" key={`linkedin_carousel_${index}`}>
                            <div className="carousel-ad__carousel__item__post">
                                <div className="carousel-ad__carousel__item__asset-wrapper">{this.getAsset(index, asset, image, video)}</div>

                                <div className="carousel-ad__carousel__item__info">
                                    <div className="carousel-ad__carousel__item__text-wrapper">
                                        <div className="carousel-ad__carousel__item__headline">
                                            <span>{headline}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {scrollLeft > 0 && (
                    <div className="carousel-ad__arrow-left" onClick={() => this.swipe('left')}>
                        <ChevronLeft fontSize="large" className="carousel-ad__arrow-icon" />
                    </div>
                )}

                {scrollLeft % 270 === 0 && framesRestructured.length > 1 && scrollLeft / 270 < frameCount - 1 && (
                    <div className="carousel-ad__arrow-right" onClick={() => this.swipe('right')}>
                        <ChevronRight fontSize="large" className="carousel-ad__arrow-icon" />
                    </div>
                )}
            </div>
        );
    }
}

export default LinkedInCarouselAd;
