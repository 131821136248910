import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';

class InstagramSingle extends React.Component {
    static propTypes = {
        /** enum: https://developers.facebook.com/docs/marketing-api/reference/ad-creative-link-data-call-to-action/ */
        cta: PropTypes.string,
        displayUrl: PropTypes.string,
        websiteUrl: PropTypes.string,
        asset: PropTypes.object
    };

    static defaultProps = {};

    getAsset = () => {
        const { assetComponents, asset, image, video, hasVideo, language } = this.props;

        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            let item = {
                asset,
                image,
                video,
                hasVideo
            };

            item = this.checkMultiLanguageVideo(item, language);

            return (
                <DynamicAsset
                    {...item.asset}
                    image={item.image}
                    video={item.video}
                    frameWidth={item.asset && item.asset.frameWidth ? item.asset.frameWidth : 1200}
                    frameHeight={item.asset && item.asset.frameHeight ? item.asset.frameHeight : 1200}
                    width={320}
                />
            );
        }
    };

    checkMultiLanguageVideo = (item, language) => {
        // If there are different video's on multiple languages, only return the correct video in the specific language
        if (item.video && item.video[language]) {
            item.video = item.video[language];
        } else if (item.video && item.video.url === undefined) {
            // If the video is dependent on a language, but there is no video available in that language, item = video:null
            // item.video.url will be undefined, because the url will be inside of the language object (item.video.EN.url)
            item.video = null;
        }

        // If the hasVideo property is true, always show the video. However, if the hasVideo property exists, but is false, remove the video.
        if (item.hasVideo) {
            item.asset = null;
            item.image = null;
        } else if (typeof item.hasVideo !== 'undefined' && !item.hasVideo) {
            item.video = null;
        }

        return item;
    };

    render() {
        return (
            <div className="single">
                <div className="single__asset-wrapper">{this.getAsset()}</div>
            </div>
        );
    }
}

export default InstagramSingle;
