import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import DynamicAsset from 'components/assets/DynamicAsset';
import iconClose from '../images/icon_close.png';
import iconArrowUp from '../images/icon_arrow_up.svg';
import iconSend from '../images/icon_instagram_send.svg';
import iconLike from '../images/icon_instagram_like.svg';
import { checkMultiLanguageVideo, getAssetsWithTimeline } from '../../helpers';

class InstagramStoriesAd extends React.Component {
    static propTypes = {
        advertiser: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }).isRequired,
        frames: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    headline: PropTypes.string,
                    displayUrl: PropTypes.string,
                    websiteUrl: PropTypes.string,
                    cta: PropTypes.string,
                    asset: PropTypes.shape({
                        type: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            ),
            PropTypes.objectOf(
                PropTypes.shape({
                    headline: PropTypes.string,
                    displayUrl: PropTypes.string,
                    websiteUrl: PropTypes.string,
                    cta: PropTypes.string,
                    asset: PropTypes.shape({
                        type: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            )
        ])
    };

    static defaultProps = {
        frames: [{}]
    };

    constructor(props) {
        super(props);

        this.instagramStoriesRef = React.createRef(null);

        this.state = {
            carouselIndex: props.carouselIndex ? props.carouselIndex : 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.instagramStoriesRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    swipe = (direction) => {
        const element = this.instagramStoriesRef.current;
        const distance = 320;
        if (direction === 'right') {
            element.scrollLeft += distance;
        } else {
            if (element.scrollLeft % distance === 0) {
                element.scrollLeft += -distance;
            } else {
                element.scrollLeft += -(element.scrollLeft % distance);
            }
        }

        setTimeout(() => {
            const index = element.scrollLeft / distance;
            this.changeIndex(Math.round(index));
        }, 340);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.instagramStoriesRef.current;
        const distance = 320;
        element.scrollLeft = (frameNr - 1) * distance;
        this.changeIndex(Math.round(frameNr - 1));
    };

    changeIndex = (i) => {
        this.props.onChangeIndex(i);
    };

    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // existing prop
        if (assetComponents) {
            if (assetComponents[index] && assetComponents[index][0]) {
                return assetComponents[index][0];
            }
            // Bricks gives the direct asset
            else if (assetComponents[index]) {
                return assetComponents[index];
            } else {
                return <React.Fragment />;
            }
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1080}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1920}
                    width={320}
                />
            );
        }
    };

    render() {
        const { advertiser, frames = [], adType, frameCount = 0, instagram_cta, cta, carouselIndex, language, items, assetComponents } = this.props;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        framesRestructured = checkMultiLanguageVideo(framesRestructured, language);

        // Also accept the old structure
        framesRestructured = framesRestructured.map((item) => {
            let link = item.link;

            if (!item.link) {
                if (item.websiteUrl) {
                    link = item.websiteUrl;
                } else {
                    link = item.displayUrl;
                }
            }

            return {
                ...item,
                link,
                name: item.name ? item.name : item.headline
            };
        });

        const assetsWithTimeline = getAssetsWithTimeline(assetComponents);
        const hasBottomSpacing = assetsWithTimeline[carouselIndex];

        const width = 100 / framesRestructured.length + '%';

        return (
            <div className={`stories-ad ${hasBottomSpacing ? 'stories-ad--bottom-spacing' : ''}`}>
                <div className="stories-ad__carousel" ref={this.instagramStoriesRef}>
                    {framesRestructured.map(({ link, asset, image, video }, index) => (
                        <div
                            key={`instagram_stories_carousel_${index}`}
                            className={`stories-ad__carousel__item ${hasBottomSpacing ? 'stories-ad__carousel__item--bottom-spacing' : ''}`}>
                            <div className="stories-ad__gradient-top"></div>

                            <div className="stories-ad__carousel__item__asset-wrapper">{this.getAsset(index, asset, image, video)}</div>

                            {cta && cta !== '' && cta !== 'NO_BUTTON' && instagram_cta[cta] && adType !== 'storiesPost' && (
                                <>
                                    <div className="stories-ad__carousel__item__swipeup">
                                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            <img className="stories-ad__carousel__item__icon-arrow-up" src={iconArrowUp} />
                                            <div className="stories-ad__carousel__item__cta">{instagram_cta[cta]}</div>
                                        </a>
                                    </div>
                                    <img className="stories-ad__carousel__item__icon-send" src={iconSend} />
                                </>
                            )}

                            {adType === 'storiesPost' && (
                                <>
                                    <div className="stories-ad__carousel__item__send-chat">Send chat</div>
                                    <img className="stories-ad__carousel__item__icon-like" src={iconLike} />
                                    <img className="stories-ad__carousel__item__icon-send" src={iconSend} />
                                </>
                            )}
                        </div>
                    ))}
                </div>

                {carouselIndex !== 0 && framesRestructured.length > 1 && (
                    <div className="stories-ad__arrow-left" onClick={() => this.swipe('left')}>
                        <ChevronLeft fontSize="large" className="stories-ad__arrow-icon" />
                    </div>
                )}

                {carouselIndex !== framesRestructured.length - 1 && framesRestructured.length > 1 && (
                    <div className="stories-ad__arrow-right" onClick={() => this.swipe('right')}>
                        <ChevronRight fontSize="large" className="stories-ad__arrow-icon" />
                    </div>
                )}

                <div className="stories-ad__progress-wrapper">
                    <div style={{ width: '100%' }}>
                        {framesRestructured.map((_item, index) => {
                            if (index < carouselIndex) {
                                return (
                                    <div key={`instagram_stories_progressbar_${index}`} className="stories-ad__progress-wrapper__progressbar" style={{ width }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={100}
                                            classes={{
                                                bar: 'stories-ad__progress-wrapper__progressbar__bar',
                                                colorPrimary: 'stories-ad__progress-wrapper__progressbar__color-primary',
                                                barColorPrimary: 'stories-ad__progress-wrapper__progressbar__bar-color-primary'
                                            }}
                                        />
                                    </div>
                                );
                            } else if (index === carouselIndex) {
                                return (
                                    <div key={`instagram_stories_progressbar_${index}`} className="stories-ad__progress-wrapper__progressbar" style={{ width }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={50}
                                            classes={{
                                                bar: 'stories-ad__progress-wrapper__progressbar__bar',
                                                colorPrimary: 'stories-ad__progress-wrapper__progressbar__color-primary',
                                                barColorPrimary: 'stories-ad__progress-wrapper__progressbar__bar-color-primary'
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={`instagram_stories_progressbar_${index}`} className="stories-ad__progress-wrapper__progressbar" style={{ width }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={0}
                                            classes={{
                                                bar: 'stories-ad__progress-wrapper__progressbar__bar',
                                                colorPrimary: 'stories-ad__progress-wrapper__progressbar__color-primary',
                                                barColorPrimary: 'stories-ad__progress-wrapper__progressbar__bar-color-primary'
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>

                <div className="stories-ad__advertiser">
                    <div className="stories-ad__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />

                    <div className="stories-ad__advertiser__text">
                        {adType === 'storiesAd' && (
                            <div>
                                <div className="stories-ad__advertiser__name">{advertiser.name}</div>
                                <div className="stories-ad__advertiser__sponsored">Sponsored</div>
                            </div>
                        )}
                        {adType === 'storiesPost' && (
                            <div>
                                <div className="stories-ad__advertiser__name-post">{advertiser.name}</div>
                            </div>
                        )}
                    </div>

                    <div className="stories-ad__advertiser__close">
                        <img className="stories-ad__advertiser__icon-close" src={iconClose} alt={'icon-close'} />
                    </div>
                </div>
            </div>
        );
    }
}

export default InstagramStoriesAd;
