import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import DynamicAsset from 'components/assets/DynamicAsset';

class InstagramCarouselAd extends React.Component {
    static propTypes = {
        advertiser: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }).isRequired,
        frames: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.string,
                    text: PropTypes.string,
                    cta: PropTypes.string
                })
            )
        ])
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            carouselIndex: props.carouselIndex ? props.carouselIndex : 0
        };
        this.instagramCarouselRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frameNr !== this.props.frameNr) {
            if (this.props.frameNr > 0) {
                this.swipeOnFrameNr(this.props.frameNr);
            }
        }
    }

    componentDidMount() {
        if (this.instagramCarouselRef.current) {
            this.swipeOnFrameNr(this.props.frameNr);
        }
    }

    swipe = (direction) => {
        const element = this.instagramCarouselRef.current;
        const distance = 320;
        if (direction === 'right') {
            element.scrollLeft += distance;
        } else {
            if (element.scrollLeft % distance === 0) {
                element.scrollLeft += -distance;
            } else {
                element.scrollLeft += -(element.scrollLeft % distance);
            }
        }

        setTimeout(() => {
            const index = element.scrollLeft / distance;
            this.changeIndex(Math.round(index));
        }, 320);
    };

    swipeOnFrameNr = (frameNr) => {
        const element = this.instagramCarouselRef.current;
        const distance = 320;
        element.scrollLeft = (frameNr - 1) * distance;
        this.changeIndex(Math.round(frameNr - 1));
    };

    changeIndex = (i) => {
        this.props.onChangeIndex(i);
    };

    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // Existing prop
        if (assetComponents) {
            if (assetComponents[index] && assetComponents[index][0]) {
                return assetComponents[index][0];
            }
            // Bricks gives the direct asset
            else if (assetComponents[index]) {
                return assetComponents[index];
            } else {
                return <React.Fragment />;
            }
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 600}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 600}
                    width={320}
                />
            );
        }
    };

    checkMultiLanguageVideo = (framesRestructured, language) => {
        // If there are different video's on multiple languages, only return the correct video in the specific language
        framesRestructured = framesRestructured.map((item) => {
            if (item.video && item.video[language]) {
                return { ...item, video: item.video[language] };
            } else if (item.video && item.video.url === undefined) {
                // If the video is dependent on a language, but there is no video available in that language, return video:null
                // item.video.url will be undefined, because the url will be inside of the language object (item.video.EN.url)
                return { ...item, video: null };
            } else {
                return { ...item };
            }
        });

        // If the hasVideo property is true, always show the video. However, if the hasVideo property exists, but is false, remove the video.
        framesRestructured = framesRestructured.map((item) => {
            if (item.hasVideo) {
                return { ...item, asset: null, image: null };
            } else if (typeof item.hasVideo !== 'undefined' && !item.hasVideo) {
                return { ...item, video: null };
            } else {
                return { ...item };
            }
        });

        return framesRestructured;
    };

    render() {
        const { frames = [], frameCount = 0, language, items } = this.props;
        let { carouselIndex } = this.props;
        let framesRestructured = [];

        // Framecount based items
        if (frameCount > 0) {
            for (let i = 1; i <= frameCount; i++) {
                framesRestructured.push(frames['frame' + i] ? frames['frame' + i] : {});
            }
        }
        // Support object based (
        else if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        framesRestructured = this.checkMultiLanguageVideo(framesRestructured, language);

        if (isNaN(carouselIndex)) {
            carouselIndex = 0;
        }

        return (
            <div className="carousel-ad">
                <div className="carousel-ad__carousel" ref={this.instagramCarouselRef}>
                    {framesRestructured.map(({ asset, image, video }, index) => (
                        <div key={index} className="carousel-ad__carousel__item">
                            <div className="carousel-ad__carousel__asset-wrapper">{this.getAsset(index, asset, image, video)}</div>
                        </div>
                    ))}
                </div>

                {carouselIndex !== 0 && framesRestructured.length > 1 && (
                    <div className="carousel-ad__arrow-left" onClick={() => this.swipe('left')}>
                        <ChevronLeft fontSize="large" className="carousel-ad__arrow-icon" />
                    </div>
                )}

                {carouselIndex !== framesRestructured.length - 1 && framesRestructured.length > 1 && (
                    <div className="carousel-ad__arrow-right" onClick={() => this.swipe('right')}>
                        <ChevronRight fontSize="large" className="carousel-ad__arrow-icon" />
                    </div>
                )}
            </div>
        );
    }
}

export default InstagramCarouselAd;
