import React from 'react';
import DynamicAsset from 'components/assets/DynamicAsset';
import './../styles/multiple.scss';

class FacebookMultiple extends React.Component {
    getAsset = (index, asset, image, video) => {
        const { assetComponents } = this.props;

        // Existing prop for assets
        if (assetComponents) {
            return assetComponents[index] && assetComponents[index][0] ? assetComponents[index][0] : <React.Fragment />;
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 1200}
                    width={230}
                />
            );
        }
    };

    render() {
        const { frameCount } = this.props;

        return (
            <div className="multiple">
                {frameCount === 1 && this.renderOneAsset()}
                {frameCount === 2 && this.renderTwoAssets()}
                {frameCount === 3 && this.renderThreeAssets()}
                {frameCount === 4 && this.renderFourAssets()}
                {frameCount > 4 && this.renderFiveAssets()}
            </div>
        );
    }

    renderOneAsset = () => {
        return (
            <div className="multiple__one box">
                <div className="multiple__one--first asset-wrapper"> {this.getAsset(0)}</div>
            </div>
        );
    };

    renderTwoAssets = () => {
        return (
            <div className="multiple__two box">
                <div className="multiple__two--first asset-wrapper"> {this.getAsset(0)}</div>
                <div className="multiple__two--second asset-wrapper"> {this.getAsset(1)}</div>
            </div>
        );
    };

    renderThreeAssets = () => {
        return (
            <div className="multiple__three box">
                <div className="multiple__three__half">
                    <div className="multiple__three--first"> {this.getAsset(0)}</div>
                </div>

                <div className="multiple__three__half">
                    <div className="multiple__three--second asset-wrapper"> {this.getAsset(1)}</div>
                    <div className="multiple__three--third asset-wrapper"> {this.getAsset(2)}</div>
                </div>
            </div>
        );
    };

    renderFourAssets = () => {
        return (
            <div className="multiple__four box">
                <div className="multiple__four__half">
                    <div className="multiple__four--item asset-wrapper"> {this.getAsset(0)}</div>
                    <div className="multiple__four--item asset-wrapper"> {this.getAsset(1)}</div>
                </div>

                <div className="multiple__four__half">
                    <div className="multiple__four--item asset-wrapper"> {this.getAsset(2)}</div>
                    <div className="multiple__four--item asset-wrapper"> {this.getAsset(3)}</div>
                </div>
            </div>
        );
    };

    renderFiveAssets = () => {
        return (
            <div className="multiple__five box">
                <div className="multiple__five__half">
                    <div className="multiple__five--first asset-wrapper"> {this.getAsset(0)}</div>
                    <div className="multiple__five--second asset-wrapper"> {this.getAsset(1)}</div>
                </div>
                <div className="multiple__five__half">
                    <div className="multiple__five--third asset-wrapper"> {this.getAsset(2)}</div>
                    <div className="multiple__five--fourth asset-wrapper"> {this.getAsset(3)}</div>
                    <div className="multiple__five--fifth asset-wrapper">
                        {this.getAsset(4)}
                        {this.props.frameCount > 5 && <div className="multiple__five--fifth__overlay">+{this.props.frameCount - 5}</div>}
                    </div>
                </div>
            </div>
        );
    };
}

export default FacebookMultiple;
