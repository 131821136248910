import React from 'react';
import PropTypes from 'prop-types';
import { withResources } from 'components/data/Resources';
import InstagramCarouselAd from './instagram-carousel-ad';
import InstagramSingle from './instagram-single';
import InstagramStoriesAd from './instagram-stories-ad';
import iconBookmark from '../images/icon_bookmark.svg';
import iconComment from '../images/icon_comment.svg';
import iconLike from '../images/icon_like.svg';
import iconShare from '../images/icon_share.svg';
import iconMore from '../images/icon_more.svg';
import iconKeyRight from '../images/icon_keyright.png';
import './../styles/main.scss';

class PreviewInstagram extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }),
        message: PropTypes.string,
        image: PropTypes.string,
        frames: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.string,
                    message: PropTypes.string,
                    cta: PropTypes.string
                })
            )
        ])
    };

    constructor(props) {
        super(props);

        this.state = {
            carouselIndex: 0,
            showFullMessage: false
        };
    }

    changeIndex = (i) => {
        const { frames } = this.state;

        if (frames && frames.length < i) {
            i = frames.length - 1;
        }

        this.setState({
            carouselIndex: i
        });
    };

    static getChild(adType, props, changeIndex, carouselIndex) {
        let component = null;

        switch (adType) {
            case 'carouselAd':
                component = <InstagramCarouselAd {...props} onChangeIndex={changeIndex} carouselIndex={carouselIndex} />;
                break;

            case 'carouselPost':
                component = <InstagramCarouselAd {...props} onChangeIndex={changeIndex} carouselIndex={carouselIndex} />;
                break;

            case 'photoAd':
                component = <InstagramSingle {...props} />;
                break;

            case 'linkAd':
                component = <InstagramSingle {...props} />;
                break;

            case 'post':
                component = <InstagramSingle {...props} />;
                break;

            case 'videoAd':
                component = <InstagramSingle {...props} />;
                break;

            case 'storiesAd':
                component = <InstagramStoriesAd {...props} onChangeIndex={changeIndex} carouselIndex={carouselIndex} />;
                break;

            case 'storiesPost':
                component = <InstagramStoriesAd {...props} onChangeIndex={changeIndex} carouselIndex={carouselIndex} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    showFullMessage = () => {
        this.setState({ showFullMessage: true });
    };

    getActiveFrame = () => {
        const { frames, items } = this.props;
        const { carouselIndex } = this.state;
        let framesRestructured = [];

        // Support object based (
        if (items && items.frame1) {
            framesRestructured = Object.values(items);
        }
        // Support array based (items)
        else if (Array.isArray(items)) {
            framesRestructured = items;
        }
        // Support object based
        else if (frames && frames.frame1) {
            framesRestructured = Object.values(frames);
        }
        // Support array based
        else if (Array.isArray(frames)) {
            framesRestructured = frames;
        }

        // Also accept the old structure
        framesRestructured = framesRestructured.map((item) => {
            if (!item) {
                item = {};
            }

            return {
                ...item,
                message: item.message ? item.message : item.text,
                link: item.link ? item.link : item.websiteUrl ? item.websiteUrl : item.displayUrl,
                name: item.name ? item.name : item.headline
            };
        });

        if (framesRestructured.length > 0 && framesRestructured[carouselIndex]) {
            return framesRestructured[carouselIndex];
        } else {
            return {};
        }
    };

    transformMessage = () => {
        const { advertiser } = this.props;
        let { message = '', name } = this.props;
        const { showFullMessage } = this.state;

        if (this.getActiveFrame().name) {
            name = this.getActiveFrame().name;
        }

        message = message ? message : this.props.text;
        if (!message) {
            message = '';
        }

        if (message) {
            let messageArray = [];
            let trimmedNameArray = [];
            let trimmedMessageArray = [];

            trimmedMessageArray = message.trim ? message.trim().split(' ') : [];

            // If there is a name (headline), than push it in front of the message
            if (name) {
                trimmedNameArray = name.trim ? name.trim().split(' ') : [];
                trimmedNameArray.push('\n');
                trimmedMessageArray = trimmedNameArray.concat(trimmedMessageArray);
            }

            // Check for enters in every word. If there are any, push them to the messageArray as an '\n'
            trimmedMessageArray.forEach((word) => {
                if (word.includes('\n')) {
                    const splitWords = word.split(/(\n)/g);
                    splitWords.forEach((word) => {
                        if (word !== '') {
                            messageArray.push(word);
                        }
                    });
                } else {
                    messageArray.push(word);
                }
            });

            // Map the messageArray and check for hashtags
            messageArray = messageArray.map((word) => {
                if (word[0] === '#') {
                    return { word, hashtag: true };
                } else {
                    return { word };
                }
            });

            let characterCount = 0;
            let filteredWordsArray = [];
            const maxLength = 85;

            // Check if the message should be truncated. If so, end with '...more'
            if (showFullMessage) {
                filteredWordsArray = messageArray;
            } else {
                messageArray.every((word) => {
                    const length = word.word.length;
                    if (characterCount + length <= maxLength) {
                        characterCount += length;
                        filteredWordsArray.push(word);
                        return true;
                    } else {
                        filteredWordsArray.push({
                            word: '...more',
                            showMore: true
                        });
                        return false;
                    }
                });
            }

            return (
                <div>
                    <span className="first-word">{advertiser.name}</span>

                    {filteredWordsArray.map((word, index) => {
                        if (word.hashtag) {
                            return (
                                <span key={word.word + index} className="hashtag">
                                    {' '}
                                    {word.word}
                                </span>
                            );
                        } else if (word.showMore) {
                            return (
                                <span key={word.word + index} className="showMore" onClick={() => this.showFullMessage()}>
                                    {' '}
                                    {word.word}
                                </span>
                            );
                        } else if (word.word === '\n') {
                            return <br />;
                        } else {
                            return <span key={word.word + index}> {word.word}</span>;
                        }
                    })}
                </div>
            );
        }
    };

    render() {
        const { adType, advertiser, frames, instagram_cta, cta } = this.props;
        let { link } = this.props;
        const { carouselIndex } = this.state;

        if (this.getActiveFrame().link) {
            link = this.getActiveFrame().link;
        }

        const hasCTA = cta && cta !== '' && cta !== 'NO_BUTTON' && instagram_cta[cta] ? true : false;

        return (
            <div className="social-preview__instagram">
                {adType !== 'storiesAd' && adType !== 'storiesPost' && (
                    <div className="social-preview__instagram__wrapper">
                        <div className="social-preview__instagram__wrapper__advertiser">
                            <div className="social-preview__instagram__wrapper__advertiser__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />

                            <div className="social-preview__instagram__wrapper__advertiser__text">
                                {adType !== 'post' && adType !== 'carouselPost' && (
                                    <div>
                                        <div className="social-preview__instagram__wrapper__advertiser__name">{advertiser.name}</div>
                                        <div className="social-preview__instagram__wrapper__advertiser__sponsored">Sponsored</div>
                                    </div>
                                )}
                                {(adType === 'post' || adType === 'carouselPost') && (
                                    <div className="social-preview__instagram__wrapper__advertiser__name-post">{advertiser.name}</div>
                                )}
                            </div>

                            <div className="social-preview__instagram__wrapper__advertiser__more">
                                <img src={iconMore} alt={'icon-more'} />
                            </div>
                        </div>

                        {PreviewInstagram.getChild(adType, this.props, this.changeIndex, carouselIndex)}

                        {hasCTA && adType !== 'post' && adType !== 'carouselPost' && (
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                <div className="social-preview__instagram__wrapper__cta">
                                    <div className="social-preview__instagram__wrapper__cta-text"> {instagram_cta[cta]} </div>
                                    <div className="social-preview__instagram__wrapper__cta-arrow">
                                        <img src={iconKeyRight} alt={'icon-keyright'} />
                                    </div>
                                </div>
                            </a>
                        )}

                        <div className="social-preview__instagram__wrapper__actions">
                            <div className="social-preview__instagram__wrapper__actions__action-left instagram-wrapper__actions__action-like">
                                <img src={iconLike} alt={'icon-like'} />
                            </div>
                            <div className="social-preview__instagram__wrapper__actions__action-left instagram-wrapper__actions__action-comment">
                                <img src={iconComment} alt={'icon-comment'} />
                            </div>
                            <div className="social-preview__instagram__wrapper__actions__action-left instagram-wrapper__actions__action-share">
                                <img src={iconShare} alt={'icon-share'} />
                            </div>
                            <div className="social-preview__instagram__wrapper__actions__action-right instagram-wrapper__actions__action-bookmark">
                                <img src={iconBookmark} alt={'icon-bookmark'} />
                            </div>

                            {(adType === 'carouselAd' || adType === 'carouselPost') && frames && frames.length > 0 && (
                                <div className="social-preview__instagram__wrapper__actions__stepper">
                                    {frames.map((item, index) => {
                                        return index === carouselIndex ? (
                                            <div
                                                key={item.displayUrl + index}
                                                className="social-preview__instagram__wrapper__actions__step social-preview__instagram__wrapper__actions__step-active"></div>
                                        ) : (
                                            <div key={item.displayUrl + index} className="social-preview__instagram__wrapper__actions__step"></div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        {(adType === 'post' || adType === 'carouselPost') && (
                            <div className="social-preview__instagram__wrapper__likes-wrapper">1.610 likes</div>
                        )}

                        <div className="social-preview__instagram__wrapper__message">{this.transformMessage()}</div>
                    </div>
                )}

                {(adType === 'storiesAd' || adType === 'storiesPost') && (
                    <div>{PreviewInstagram.getChild(adType, this.props, this.changeIndex, carouselIndex)}</div>
                )}
            </div>
        );
    }
}

export default withResources(PreviewInstagram, ['instagram_cta']);
